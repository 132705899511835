import { myVanToast } from "@/plugins/vant.js";
import i18n from "@/plugins/i18n.js";
import BigNumber from "bignumber.js";

export const copyText = (item, type) => {
    var input = document.createElement("textarea"); // js创建一个input输入框
    input.value = item; // 将需要复制的文本赋值到创建的input输入框中
    document.body.appendChild(input); // 将输入框暂时创建到实例里面
    input.select(); // 选中输入框中的内容
    document.execCommand("Copy"); // 执行复制操作
    document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
    type
        ? null
        : myVanToast({
              type: "success",
              message: i18n.global.t("public.copy_success"),
          });
};

export const isH5 = () => {
    let sUserAgent = navigator.userAgent.toLowerCase();
    let bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    let bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    let bIsMidp = sUserAgent.match(/midp/i) == "midp";
    let bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    let bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    let bIsUcBrowser = sUserAgent.match(/ucbrowser/i) == "ucbrowser";
    let bIsAndroid = sUserAgent.match(/android/i) == "android";
    let bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    let bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";

    if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM || bIsUcBrowser) {
        return true;
    } else {
        return false;
    }
};

export const getRequest = () => {
    var a = decodeURI(location.href).split("&last")[0]; //获取url中"?"符后的字串
    var url = "?" + a.split("?")[1];
    var theRequest = new Object();
    if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = [];
        strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
    }
    return theRequest;
};

export const getCookie = (cname) => {
    var aCookie = document.cookie.split("; ");
    for (var i = 0; i < aCookie.length; i++) {
        var aCrumb = aCookie[i].split("=");
        if (cname === aCrumb[0]) return aCrumb[1];
    }
    return "";
};

export const setCookie = function (name, value) {
    //cookie过期时间1年
    var Days = 365;
    var exp = new Date();
    exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
    var cookieText = encodeURIComponent(name) + "=" + encodeURIComponent(value) + "; expires=" + exp.toGMTString() + "; path=/";
    document.cookie = cookieText;
};
// export const setCookie = function (name, value) {
//     var cookieText = encodeURIComponent(name) + "=" + encodeURIComponent(value) + "; path=/";
//     document.cookie = cookieText;
// };
// 因为多了时间参数，剩下的pathcookie也会自动取页面值设置上，造成父子路由嵌套时错误
// export const setCookie = (cname, cvalue) => {
//     //cookie过期时间1年
//     var Days = 365;
//     var exp = new Date();
//     exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
//     document.cookie = cname + "=" + cvalue + ";expires=" + exp.toGMTString();
//     // document.cookie = cname + "=" + cvalue + ";expires=" + exp.toGMTString() + ";path=/";
// };

export const formatAddress = (address, s = 10, e = 4) => {
    if (address) {
        const prefix = address.substring(0, s);
        const suffix = address.substring(address.length - e);
        return `${prefix}****${suffix}`;
    } else {
        return `...`;
    }
};

//返回月日年时分秒格式
export const formatEn = (time) => {
    //shijianchuo是整数，否则要parseInt转换
    if (time == "" || time == 0) {
        return "...";
    } else {
        var timer = new Date(time * 1000);
        var y = timer.getFullYear();
        var m = timer.getMonth() + 1;
        var d = timer.getDate();
        var h = timer.getHours();
        var mm = timer.getMinutes();
        // var s = timer.getSeconds();
        return m + "/" + add0(d) + "/" + add0(y) + " " + add0(h) + ":" + add0(mm);
    }
};
function add0(m) {
    return m < 10 ? "0" + m : m;
}

export const numToString = (num) => {
    let m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
    return num.toFixed(Math.max(0, (m[1] || "").length - m[2]));
};

function truncateDecimalPlaces(number, decimalPlaces = 4) {
    const bigNumber = new BigNumber(number);
    return bigNumber.toFixed(decimalPlaces, BigNumber.ROUND_DOWN);
}

// 示例用法
export const exampleUsage = (numberToTruncate, decimalPlacesToKeep) => {
    const result = truncateDecimalPlaces(numberToTruncate, decimalPlacesToKeep);
    return result;
};
export const formatNumberWithKMB = (num, decimal = 8) => {
    if (!num) {
        return 0;
    } else if (num < 1000) {
        return numToString(new BigNumber(num).dp(decimal, BigNumber.ROUND_DOWN));
    } else if (num < 1000000) {
        return numToString(new BigNumber(num).div(new BigNumber(1000)).dp(decimal, BigNumber.ROUND_DOWN)) + "K";
    } else {
        return numToString(new BigNumber(num).div(new BigNumber(1000000)).dp(decimal, BigNumber.ROUND_DOWN)) + "M";
    }
};

export const format = (itemTime) => {
    //shijianchuo是整数，否则要parseInt转换
    if (itemTime == "" || itemTime == 0) {
        return "...";
    } else {
        var time = new Date(itemTime * 1000);
        var y = time.getFullYear();
        var m = time.getMonth() + 1;
        var d = time.getDate();
        var h = time.getHours();
        var mm = time.getMinutes();
        var s = time.getSeconds();
        return y + "." + add0(m) + "." + add0(d) + " " + add0(h) + ":" + add0(mm) + ":" + add0(s);
    }
};

// swap使用
const numToString1 = (num) => {
    let m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
    return num.toFixed(Math.max(0, (m[1] || "").length - m[2]));
};
export const numToStringtoFixed = (num, digits) => {
    return Number(Number(numToString1(num)).toFixed(digits));
};
