export default {
    public: {
        copy_success: "复制成功",
        download_success: "保存成功",
        button: {
            text: "确定",
            jump: "跳过",
            text_ok: "确认",
            text_ok1: "好的",
            cancel: "取消",
        },
        networkError: {
            text1: "错误，请重试！",
            text2: "登录过期或出现错误，请重新登录！",
            text3: "请求的资源不存在！",
            mail: "请在Supe App内打开!",
            errorProcess: "您不在App环境内，请打开Supe App访问！",
            errorMethodsNotDefine: "方法不存在!",
        },
        refresh: {
            text1: "Drag down to referesh...",
            text2: "Release to referesh...",
            text3: "Loading...",
            text4: "Loading successfully~",
            text5: "noMore~",
        },
    },
    header2: {
        title_wallet: "Wallet",
        title_task_points: "Wish Points",
        title_nft: "My Fox NFT",
        title_swap_history: "Swap Record",
    },
    header1: {
        text1: "Login With Email",
        text2: "Complete Profile",
        text3: "Start a new mine",
        text4: "My mining team",
        text5: "Wish Point",
    },
    task: {
        text1: "Follow Twitter",
        text2: "Join Discord",
        text3: "Join Telegram",
        text4: "Refer Friends",
        text5_0: "GO",
        text5_1: "Done",
    },
    swap: {
        text1: "Real-time Price",
        text2: "Exchange",
        text3: "Exchange tokens instantly",
        text7: "Price",
        text8: "Price Impact",
        text9: "Enter Amount",
        text10: "Input Value Too Small",
        text11: "Insufficient Liquidity",
        text12: "Failed to add liquidity",
        text13: "Exchange",
        text14: "Output Value Too Small",
    },
};
