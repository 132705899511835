import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import { getCookie } from "@/utils";
import store from "@/store";
import { myVanToast } from "@/plugins/vant.js";
import useSetInviter from "@/hooks/useSetInviter.js";
import api from "@/service/api.js";
const router = createRouter({
    linkActiveClass: "active",
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 };
    },
});

router.beforeEach((to, from, next) => {
    const isUserLoggedIn = store.state.isUserLoggedIn;
    var token = getCookie("access_token_new");
    // 如果路由是空，且存在 token，重定向到 "market" 页面
    if (to.name == "page" && token !== "" && store.state.isUserLoggedIn) {
        next("/market");
    } else if (to.name == "page" && token !== "" && !store.state.isUserLoggedIn) {
        next("/login");
    } else if (token !== "") {
        // 如果存在 token，停留在当前页面
        next();
    } else if (to.name !== "page" && to.name !== "login") {
        // 如果没有 token 且访问的不是 "page" 和 "login"，重定向到 "page"
        next("/page");
    } else {
        // 如果没有 token 且访问的是 "page" 或 "login"，继续访问
        next();
    }
    try {
        const code = to.query.invite_code;
        if (code) {
            // 设置邀请人
            store.commit("updateUserInviteCode", code);
            if (isUserLoggedIn && code) {
                const storeInviteInfo = store.state.inviteInfo;
                useSetInviter(store, myVanToast, api, storeInviteInfo);
            }
        } else {
            store.commit("updateUserInviteCode", "");
        }
    } catch (err) {
        store.commit("updateUserInviteCode", "");
    }
});

export default router;
