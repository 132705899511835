const Wallet = () => import("@/views/Wallet");
const Deposit = () => import("@/components/Wallet/Deposit");
const Withdraw = () => import("@/components/Wallet/Withdraw");
const Send = () => import("@/components/Wallet/Send");
// const Home = () => import("@/views/Home");
const Page = () => import("@/views/Page");
const Login = () => import("@/views/Login");
const Profile = () => import("@/views/Profile");
const Market = () => import("@/views/Market");
const Mining = () => import("@/views/Mining");
const Games = () => import("@/views/Games");
const BorrowAnRepay = () => import("@/views/BorrowAnRepay");
const GpetPoint = () => import("@/views/GpetPoint");
const TaskPoints = () => import("@/views/TaskPoints");
const MyNft = () => import("@/components/MyNft");
const SpetSwap = () => import("@/views/SpetSwap");
const Swap = () => import("@/views/Swap");
const SwapHistory = () => import("@/components/Swap/history");
const InviteCode = () => import("@/views/InviteCode");
const routes = [
    {
        path: "/",
        redirect: {
            name: "page",
        },
    },
    {
        path: "/wallet",
        name: "wallet",
        component: Wallet,
        redirect: {
            name: "deposit",
        },
        children: [
            {
                path: "deposit",
                name: "deposit",
                component: Deposit,
            },
            {
                path: "withdraw",
                name: "withdraw",
                component: Withdraw,
            },
            {
                path: "send",
                name: "send",
                component: Send,
            },
        ],
    },
    {
        path: "/page",
        name: "page",
        component: Page,
    },
    {
        path: "/login",
        name: "login",
        component: Login,
    },
    {
        path: "/profile",
        name: "profile",
        component: Profile,
    },
    {
        path: "/inviteCode",
        name: "inviteCode",
        component: InviteCode,
    },
    {
        path: "/market",
        name: "market",
        component: Market,
    },
    {
        path: "/mining",
        name: "mining",
        component: Mining,
    },
    {
        path: "/games",
        name: "games",
        component: Games,
    },

    {
        path: "/borrowAnRepay",
        name: "borrowAnRepay",
        component: BorrowAnRepay,
        meta: {
            type: "spet",
        },
    },
    {
        path: "/gpetPoint",
        name: "gpetPoint",
        component: GpetPoint,
    },
    {
        path: "/task-points",
        name: "task-points",
        component: TaskPoints,
    },
    {
        path: "/my-nft",
        name: "my-nft",
        component: MyNft,
    },
    {
        path: "/spet",
        name: "spet",
        component: SpetSwap,
        meta: {
            type: "spet",
        },
        redirect: {
            name: "swap",
        },
        children: [
            {
                path: "swap",
                name: "swap",
                component: Swap,
            },
        ],
    },
    {
        path: "/swap-history",
        name: "swap-history",
        component: SwapHistory,
    },
];
export default routes;
