import { ElButton, ElInput, ElInputNumber, ElForm, ElFormItem } from "element-plus";
export default {
  components: {
    ElButton,
    ElInput,
    ElInputNumber,
    ElForm,
    ElFormItem
  },
  methods: {
    // 可以写入全局方法等
  }
};