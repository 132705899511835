//统一管理api
import { base_pets_cdwar } from "./base"; // 导入接口域名列表
import { post, _post } from "./tool"; // 导入http中创建的axios实例
//登录
export const setUserLogin = (params) => post(base_pets_cdwar + "/user/login", params);
// 发送验证码
export const setEmailVerifyCode = (params) => post(base_pets_cdwar + "/user/send/email_verify_code", params);
//腾讯云签名
export const getStorageCred = (params) => _post(base_pets_cdwar + "/storage/cred", params);
//设置用户信息
export const setUserInfo = (params) => _post(base_pets_cdwar + "/user/set_user_info", params);
//获取借贷数据
export const getBorrowConfig = (params) => _post(base_pets_cdwar + "/borrow/info", params);
//借款接口
export const borrowSymbol = (params) => _post(base_pets_cdwar + "/borrow/pay", params);
//借款历史记录
export const borrowHistory = (params) => _post(base_pets_cdwar + "/borrow/history", params);
//获取还款列表
export const borrowReturnList = (params) => _post(base_pets_cdwar + "/borrow/return/list", params);
//还款历史记录
export const returnHistory = (params) => _post(base_pets_cdwar + "/return/history", params);
//还款接口
export const borrowReturn = (params) => _post(base_pets_cdwar + "/borrow/return", params);

// 游戏钱包信息
export const getAssetCoin = (params) => _post(base_pets_cdwar + "/user/asset/coin", params);
// Withdraw
export const sendWithdraw = (params) => _post(base_pets_cdwar + "/withdraw", params);
// Send
export const sendTransferCoin = (params) => _post(base_pets_cdwar + "/withdraw/transfer_coin", params);
// 获取积分信息
export const getPointInfo = (params) => _post(base_pets_cdwar + "/point/info", params);
// 领取积分
export const getPointClaim = (params) => _post(base_pets_cdwar + "/point/claim", params);
// 设置邀请人
export const setInvite = (params) => _post(base_pets_cdwar + "/invite/set", params);
// 获取白名单相关信息
export const getWhiteList = (params) => _post(base_pets_cdwar + "/whitelist/info", params);
// 通过兑换点数加入白名单
export const joinWhiteList = (params) => _post(base_pets_cdwar + "/whitelist/join", params);
//归还NFT
export const returnNFT = (params) => _post(base_pets_cdwar + "/nft/recycle", params);

//市场获取nft配置
export const getNFTInfo = (params) => _post(base_pets_cdwar + "/nft/info", params);
//开盲盒
export const openNFT = (params) => _post(base_pets_cdwar + "/nft/open", params);
//获取NFT列表
export const getNFTlist = (params) => _post(base_pets_cdwar + "/nft/list", params);
//修复
export const repairNFT = (params) => _post(base_pets_cdwar + "/nft/repair", params);
//领取
export const claimEarn = (params) => _post(base_pets_cdwar + "/nft/claim", params);
//swap - 配置
export const getSwapInfo = (params) => _post(base_pets_cdwar + "/swap/info", params);
//swap 数量转换
export const getSwapCal = (params) => _post(base_pets_cdwar + "/swap/cal", params);
//swap 支付
export const sendSwapPay = (params) => _post(base_pets_cdwar + "/swap/do", params);
//swap交易记录
export const getSwapHistory = (params) => _post(base_pets_cdwar + "/swap/history", params);

export default {
    setUserLogin,
    setEmailVerifyCode,
    getStorageCred,
    setUserInfo,
    getBorrowConfig,
    getAssetCoin,
    sendWithdraw,
    sendTransferCoin,
    getPointInfo,
    getPointClaim,
    borrowSymbol,
    borrowHistory,
    borrowReturnList,
    borrowReturn,
    returnHistory,
    setInvite,
    getNFTInfo,
    openNFT,
    getNFTlist,
    repairNFT,
    claimEarn,
    getSwapInfo,
    getSwapCal,
    sendSwapPay,
    getSwapHistory,
    getWhiteList,
    joinWhiteList,
    returnNFT,
};
