import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
const debug = process.env.NODE_ENV !== "production";
import { getSwapInfo } from "../service/api";

const state = {
    lang: "en-US",
    petLoading: false,
    isUserLoggedIn: false,
    userInfo: {},
    centerAssets: [], //用户持有的币种
    supportedAssets: [], //支持的币种
    foxNFTTips: false, //提示框提示状态
    inviteInfo: {}, // 存储邀请人
    userInviteCode: "",
    activeNFT: 0, //当前选中的NFT的标识
    swapConfigInfo: {}, //swap配置信息
};

const mutations = {
    updateLang(state, value) {
        state.lang = value;
    },
    updatePetLoading(state, value) {
        state.petLoading = value;
    },
    updateIsUserLoggedIn(state, value) {
        state.isUserLoggedIn = value;
    },
    updateUserInfo(state, value) {
        state.userInfo = value;
    },
    updateCenterAssets(state, value) {
        state.centerAssets = value;
    },
    updateSupportedAssets(state, value) {
        state.supportedAssets = value;
    },
    updateFoxNFTTips(state, value) {
        state.foxNFTTips = value;
    },
    // 存储邀请人
    updateInviteInfo(state, value) {
        state.inviteInfo = value;
    },
    updateUserInviteCode(state, value) {
        state.userInviteCode = value;
    },
    updateActiveNFT(state, value) {
        state.activeNFT = value;
    },
    updateSwapConfigInfo(state, value) {
        state.swapConfigInfo = value;
    },
};
const actions = {
    //swap config
    actionsGetSwapInfo({ commit }, value) {
        return new Promise((resolve, reject) => {
            getSwapInfo(value)
                .then((res) => {
                    if (!res.error_code) {
                        commit("updateCenterAssets", res.common.center_assets);
                        commit("updateSwapConfigInfo", res.data);
                    } else {
                        commit("updateSwapConfigInfo", {});
                    }
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};

export default createStore({
    state,
    mutations,
    actions,
    strict: debug,
    plugins: [createPersistedState()],
});
