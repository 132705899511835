export default {
    public: {
        copy_success: "Copied successfully",
        download_success: "Saved successfully",
        button: {
            text: "Confirm",
            jump: "Skip",
            text_ok: "Confirm",
            text_ok1: "OK",
            cancel: "Cancel",
        },
        networkError: {
            text1: "Error, please try again!",
            text2: "Login expired or error occurred, please login again!",
            text3: "The requested resource does not exist!",
            mail: "Please open it in the Supe App!",
            errorProcess: "You are not in the App environment, please open the Supe App to visit!",
            errorMethodsNotDefine: "The method does not exist!",
        },
        refresh: {
            text1: "Drag down to referesh...",
            text2: "Release to referesh...",
            text3: "Loading...",
            text4: "Loading successfully~",
            text5: "noMore~",
        },
    },
    header2: {
        title_wallet: "Wallet",
        title_task_points: "Wish Points",
        title_nft: "My Fox NFT",
        title_swap_history: "Swap Record",
    },
    header1: {
        text1: "Login With Email",
        text2: "Complete Profile",
        text3: "Start a new mine",
        text4: "My mining team",
        text5: "Wish Point",
    },
    task: {
        text1: "Follow Twitter",
        text2: "Join Discord",
        text3: "Join Telegram",
        text4: "Refer Friends",
        text5_0: "GO",
        text5_1: "Done",
    },
    swap: {
        text1: "Real-time Price",
        text2: "Exchange",
        text3: "Exchange tokens instantly",
        text7: "Price",
        text8: "Price Impact",
        text9: "Enter Amount",
        text10: "Input Value Too Small",
        text11: "Insufficient Liquidity",
        text12: "Failed to add liquidity",
        text13: "Exchange",
        text14: "Output Value Too Small",
    },
};
